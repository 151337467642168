<template>
  <div>
    <h3 class="modal__title">Change Email Address</h3>
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-email
          v-model="email"
          name="New email address"
          placeholder="Enter your email address"
        >
          <h3 slot="label">New Email Address</h3>
        </v-email>

        <validation-provider
          name="confirmed"
          :rules="{ is: email }"
          v-slot="{ errors }"
        >
          <v-email
            v-model="confirm"
            name="Confirmed email address"
            placeholder="Repeat your new email address"
          >
            <h3 slot="label">Confirm Email Address</h3>
            <div class="error-message mt" v-if="errors[0]" slot="error">
              Emails do not match
            </div>
          </v-email>
        </validation-provider>

        <div v-if="error" v-html="error" class="text-danger" />

        <div class="modal__footer">
          <button
            type="button"
            class="button button_text button_md"
            @click="onClose"
          >
            cancel
          </button>
          <button type="submit" class="button button_secondary button_md">
            submit
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vEmail from "../formElements/email";
import { saveLog } from '@/modules/logger';

import {
  STORE_CHANGE_EMAIL,
  POPUP_NOTIFICATION,
  MSG_EMAIL_UPDATED,
} from "@/constants";

export default {
  components: { vEmail },

  inject: {
    showModal: { default: () => {} },
  },

  data: () => ({
    email: "",
    confirm: "",
    error: null,
    loading: false,
  }),

  computed: {
    isEqual() {
      return this.email === this.confirm;
    },
  },

  methods: {
    ...mapActions([STORE_CHANGE_EMAIL]),

    onSubmit() {
      this.loading = true;
      this.error = null;
      saveLog("submit-change-email");

      this[STORE_CHANGE_EMAIL]({
        email: this.email,
      })
        .then(this.onSuccess)
        .catch(this.errorHandler)
        .finally(this.finalHandler);
    },

    finalHandler() {
      this.loading = false;
    },

    onClose() {
      saveLog("change-email-close");
      this.$emit("close");
    },

    onSuccess() {
      saveLog("change-email-success");
      this.onClose();
      this.showModal(POPUP_NOTIFICATION, {
        title: MSG_EMAIL_UPDATED,
        icon: "check",
      });
    },

    errorHandler(error) {
      if (!error?.response?.data) return;
      saveLog("change-email-failure", {error : error.response.data});
      // this.error = formatErrorsToString(error.response.data);
      this.error = "This email address already exists, please use a different email address";
    },
  },
};
</script>
